import React, { useEffect, useState } from 'react'
import AiImg from '../../../Images/aiindo.jpeg'
import NavbarComponent from '../../../components/Navbar/NavbarComponent'
import FooterComponent from '../../../components/Footer/FooterComponent'
import '../../GlobalDetailPage.css'
import { Button, Spinner, Row, Col, Card } from 'react-bootstrap'


const AiPage = () => {
    const [loading, isLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            isLoading(false);
        }, 3000);
        return () => clearTimeout(timer)
    }, []);

    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = '//pl23664610.highrevenuenetwork.com/37/4a/83/374a83732a9ad95c18ad5281d5097e1b.js';
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    if (loading) {
        return <div className="spinner-container">
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>;
    }

    return (
        <div className='detail-main-page'>
            <NavbarComponent />

            <div className=''>
                <div className='img-cover-section'>
                    <img className='img-cover' src={AiImg} alt="" />
                </div>
            </div>
            <div className=''>

                <Row className='d-flex align-item-center justify-content-center'>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/16jUz2I_VnmOFlgAzokxk973CVizZbC6r?usp=sharing">Episode 1</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/11QT5gQIUut8Ul6EBCXl4OWHMLwo0NzSm?usp=sharing">Episode 2</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1jXnZbuoI49jlz9lMt8zvYvpCqgQydD9J?usp=drive_link">Episode 3</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1zLTOnF8lcarxSAz_2j8WvtoAOA8FLh-z?usp=sharing">Episode 4</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1PJo2ZXfJ2wR8JJjsc6Vm8S9YlIXmWvvB?usp=drive_link">Episode 5</Button>
                    </Col>
                </Row>
            </div>

            <Card>
                <Card.Header>Attention!</Card.Header>
                 <Card.Body>
                    <p>Tonton video viral gratis di google drive.</p>
                    <p>Jika google drive tidak bisa memainkan video, download saja videonya.</p>
                    <p>Jika link ada yang error, laporkan link dengan menekan tombol di bawah ini</p>
                    <Button variant='outline-dark'>Laporkan link</Button>
                 </Card.Body>
            </Card>

            <FooterComponent />
        </div>
    )
}

export default AiPage
