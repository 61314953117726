import React, {useState, useEffect} from 'react'
import SintaMahaliniImg from '../../../Images/sintamahalini.jpg'
import NavbarComponent from '../../../components/Navbar/NavbarComponent'
import FooterComponent from '../../../components/Footer/FooterComponent'
import '../../GlobalDetailPage.css'
import { Button, Spinner, Row, Col, } from 'react-bootstrap'

const SintaMahalini = () => {
    const [loading, isLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            isLoading(false);
        }, 3000);
        return () => clearTimeout(timer)
    }, []);

    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = '//pl23664610.highrevenuenetwork.com/37/4a/83/374a83732a9ad95c18ad5281d5097e1b.js';
        script.async = true;
    
        document.body.appendChild(script);
    
        return () => {
          document.body.removeChild(script);
        };
      }, []);


    if (loading) {
        return <div className="spinner-container">
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>;
    }

    return (
        <div className='detail-main-page'>
            <NavbarComponent />
            
            <div className=''>
                <div className='img-cover-section'>
                    <img className='img-cover' src={SintaMahaliniImg} alt="" />
                </div>
            </div>
            <div className=''>
               
                    <Row className='d-flex align-item-center justify-content-center'>
                        <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                            <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1LSvICK3oe7yrpnB4CpsNJcSgti34pgqX?usp=drive_link">Episode 1</Button>
                        </Col>
                        <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                            <Button className='btn-episode' variant='outline-dark' href='https://drive.google.com/drive/folders/1mHsPKWG6ul5yF9yMXiR3dHqz45v7YnSs?usp=drive_link'>Episode 2</Button>
                        </Col>
                        <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                            <Button className='btn-episode' variant='outline-dark' href='https://drive.google.com/drive/folders/1MqhHqZj0uyEDhzULIB37RzG-o1ZW2TC7?usp=drive_link'>Episode 3</Button>
                        </Col>
                        <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                            <Button className='btn-episode' variant='outline-dark' href='https://drive.google.com/drive/folders/1c97shiOlgqUvFvRFXQ0V8KBhtY4ufA8A?usp=drive_link'>Episode 4</Button>
                        </Col>
                        <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                            <Button className='btn-episode' variant='outline-dark' href='https://drive.google.com/drive/folders/1KHaUL0kTvNbap9rSeHUGsymulpLxybay?usp=drive_link'>Episode 5</Button>
                        </Col> 
                        <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                            <Button className='btn-episode' variant='outline-dark' href='https://drive.google.com/drive/folders/1TnUsNj7-mj74bptqoLwwPeo4GNE1ivhS?usp=drive_link'>Episode 6</Button>
                        </Col>
                        <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                            <Button className='btn-episode' variant='outline-dark' href='https://drive.google.com/drive/folders/1G4alu-05YgW7y-uVnoROVFkHtQuVfjkn?usp=drive_link'>Episode 7</Button>
                        </Col>
                        <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                            <Button className='btn-episode' variant='outline-dark' href='https://drive.google.com/drive/folders/1rCWoybwzfWJLaP2V4gtKw8OOmBDBdd-r?usp=drive_link'>Episode 8</Button>
                        </Col>
                        <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                            <Button className='btn-episode' variant='outline-dark' href='https://drive.google.com/drive/folders/1kM2QcUkbz0uyMdLDiT3hmE3w7MV1agBD?usp=drive_link'>Episode 9</Button>
                        </Col>
                        
                    </Row>
            </div>

            <FooterComponent />
        </div>
    )
}

export default SintaMahalini
