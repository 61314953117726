import React, { useEffect, useState } from 'react'
import YumimiaImg from '../../../Images/yumimia.PNG'
import NavbarComponent from '../../../components/Navbar/NavbarComponent'
import FooterComponent from '../../../components/Footer/FooterComponent'
import '../../GlobalDetailPage.css'
import { Button, Spinner, Row, Col, } from 'react-bootstrap'

const YumimiaPage = () => {
    const [loading, isLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            isLoading(false);
        }, 3000);
        return () => clearTimeout(timer)
    }, []);
  
    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = '//pl23664610.highrevenuenetwork.com/37/4a/83/374a83732a9ad95c18ad5281d5097e1b.js';
        script.async = true;
  
        document.body.appendChild(script);
  
        return () => {
            document.body.removeChild(script);
        };
    }, []);
  
    if (loading) {
        return <div className="spinner-container">
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>;
    }
  
    return (
        <div className='detail-main-page'>
            <NavbarComponent />
  
            <div className=''>
                <div className='img-cover-section'>
                    <img className='img-cover' src={YumimiaImg} alt="" />
                </div>
            </div>
            <div className=''>
                <Row className='d-flex align-item-center justify-content-center'>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/12O5xmmGhdWcrCbJs6uGRPrx4QGbs3baw?usp=drive_link">Episode 1</Button>
                    </Col>
                </Row>
            </div>
          
            <FooterComponent />
        </div>
    )
}

export default YumimiaPage