import React, { useState } from "react";
import './NavbarComponent.css';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';


const NavbarComponent = ({ onSearch }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSearch = (e) => {
        e.preventDefault();
        onSearch(searchTerm);
    };

    return (
        <div className="navbar-section">
            <Navbar expand="lg" className="bg-body-tertiary ">
                <Container fluid > 
                    <Navbar.Brand href="/" className="mx-auto">
                        <img className="vvh-logo" src="vvh-png.png" alt="Example" style={{width: '100px',}} />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="my-2 my-lg-0"
                            style={{  textAlign: 'center' }}
                            navbarScroll
                        >
                            <Nav.Link href="/">Home</Nav.Link>
                            <Nav.Link href="#action2">Forum</Nav.Link>
                            <Nav.Link href="#action2">Games</Nav.Link>
                            <Nav.Link href="#action2">Dating</Nav.Link>
                            <Nav.Link href="#action2">Pictures</Nav.Link>
                        </Nav>

                    </Navbar.Collapse>

                </Container>

            </Navbar>
            <Form className="d-flex search-bar" onSubmit={handleSearch}>
                <Form.Control
                    type="search"
                    placeholder="Search"
                    className="me-2"
                    aria-label="Search"
                    value={searchTerm}
                    onChange={handleInputChange}
                />
                <Button variant="outline-dark" type="submit">Search</Button>
            </Form>

        </div>
    );
};

export default NavbarComponent;