
import AdelaaImg from '../Images/adela.jpg'
import AiImg from '../Images/aiindo.jpeg'
import AndiImg from '../Images/Andini.PNG'
import ArrayesaImg from '../Images/Arrayesa.PNG'
import AudreyImg from '../Images/audrey.jpeg'
import AuliaImg from '../Images/Aulia.PNG'
import ChikaImg from '../Images/Chika.jpg'
import EvaImg from '../Images/Eva.jpg'
import IrdaImg from '../Images/irdanelisa.jpg'
import KaylaImg from '../Images/kayla.PNG'
import LalaImg from '../Images/Lala.PNG'
import BabyImg from '../Images/Baby.jpg'
import MaeganImg from '../Images/maegan.jpg'
import HannaImg from '../Images/hanna.jpg'
import PutiImg from '../Images/Puti.PNG'
import RiaImg from '../Images/ria.jpg'
import Salsa from '../Images/Salsa.jpg'
import Sani from '../Images/Sani.jpg'
import SariImg from '../Images/sari.jpg'
import ShaShaImg from '../Images/ShaSha.jpg'
import SindiIng from '../Images/sindinisrina.jpg'
import SintaMahaliniImg from '../Images/sintamahalini.jpg'
import AngleicaImg from '../Images/angelica.jpg'
import NanaImg from '../Images/nana.png'
import WindaImg from '../Images/Winda.jpg'
import Pasangan1Img from '../Images/pasangan1.jpg'
import Pasangan2Img from '../Images/pasangan2.jpg'
import AlicelebelImg from '../Images/alicelebel.PNG'
import JenniferImg from '../Images/jennifer.PNG'
import IyviwhiteImg from '../Images/iyvwhyte.PNG'
import EmawishImg from '../Images/emmawish.PNG'
import KatebleareImg from '../Images/katebleare.PNG'
import LovejuliaImg from '../Images/lovejuliya.PNG'
import OnechewImg from '../Images/onee-chew.PNG'
import YumimiaImg from '../Images/yumimia.PNG'


const CardData = [
    { id: 'white girls, yumimia, sexy girl, europan girl, girl', title: 'Yumimia the nice actress girl', image: YumimiaImg, link: '/yumimia' },
    { id: 'white girls, onee-chew, sexy girl, europan girl, girl', title: 'Asian nice big body', image: OnechewImg, link: '/onee-chew' },
    { id: 'Nana, cewe, cewe indo, tobrut', title: 'Nana rambut pendek montok', image: NanaImg, link: '/nana' },
    { id: 'Angelica, cewe, cewe indo, tobrut', title: 'Angelica chindo sexy', image: AngleicaImg, link: '/angelica' },
    { id: 'white girls, julia, sexy girl, europan girl, girl', title: 'Love julia perfect nice body', image: LovejuliaImg, link: '/lovejulia' },
    { id: 'white girls, kate bleare, sexy girl, europan girl, girl', title: 'Good girl nice body', image: KatebleareImg, link: '/katebleare' },
    { id: 'white girls, emma wish, sexy girl, europan girl, girl', title: 'Nice body emma wish', image: EmawishImg, link: '/emmawish' },
    { id: 'Salsa, cewe, cewe indo, tobrut', title: 'Salsa lemah gemulai', image: Salsa, link: '/salsa' },
    { id: 'Ria, cewe, cewe indo, tobrut', title: 'Ria istri penurut', image: RiaImg, link: '/ria' },
    { id: 'white girls, sexy girl, ivywhyte, europan girl, girl', title: 'Sexy girls nice pusy', image: IyviwhiteImg, link: '/ivywhyte' },
    { id: 'white girls, jennifer, sexy girl, europan girl, girl', title: 'Jenifer nice body', image: JenniferImg, link: '/jennifer' },
    { id: 'white girls, alicelebel, sexy girl, europan girl, girl', title: 'Europan alicelebel', image: AlicelebelImg, link: '/alicelebel' },
    { id: 'Pasangan, sagne, sange cewe, cewe indo, tobrut', title: 'Mantap mantap dengan pasangan', image: Pasangan2Img, link: '/pasangan2' },
    { id: 'Winda, cewe, cewe indo, tobrut', title: 'Bocil montok doyan fupfup', image: WindaImg, link: '/winda' },
    { id: 'Sinta Mahalini, cewe, cewe indo, tobrut', title: 'Sinta Mahalini Putri', image: SintaMahaliniImg, link: '/sintamahalini' },
    { id: 'Sindi, cewe, cewe indo, tobrut', title: 'Sindi Nisrina Viral', image: SindiIng, link: '/sindinisrina' },
    { id: 'Shasha, cewe, cewe indo, tobrut', title: 'Shasha si imut', image: ShaShaImg, link: '/shasha' },
    { id: 'Pasangan, sagne, sange cewe, cewe indo, tobrut', title: 'Pasangan sagne', image: Pasangan1Img, link: '/pasangan1' },
    { id: 'Sari, cewe, cewe indo, tobrut', title: 'Sari anak muda yang bergairah', image: SariImg, link: '/sari' },
    { id: 'Sani, cewe, cewe indo, tobrut', title: 'Sani vcs gratis', image: Sani, link: '/sani' },
    { id: 'Puti, cewe, cewe indo, tobrut', title: 'Hijabers tobrut Puti', image: PutiImg, link: '/puti' },
    { id: 'Hanna, cewe, cewe indo, tobrut', title: 'Nur Hanna Fannesa vcs', image: HannaImg, link: '/hanna' },
    { id: 'Maegan, cewe, cewe indo, tobrut', title: 'China body mulus Maegan', image: MaeganImg, link: '/maegan' },
    { id: 'Lala, cewe, cewe indo, tobrut', title: 'Lala hijabers tobrut', image: LalaImg, link: '/lala' },
    { id: 'Kayla, cewe, cewe indo, tobrut', title: 'Kayla Ngews', image: KaylaImg, link: '/kayla' },
    { id: 'Irda, cewe, cewe indo, tobrut', title: 'Irda Indonesian People', image: IrdaImg, link: '/irda' },
    { id: 'Eva, cewe, cewe indo, tobrut', title: 'Eva bermain sangat santai', image: EvaImg, link: '/eva' },
    { id: 'Chika, cewe, cewe indo, tobrut', title: 'Artist tiktok Chika', image: ChikaImg, link: '/chika' },
    { id: 'Baby, cewe, cewe indo, tobrut', title: 'Baby bodynya sangat bohai', image: BabyImg, link: '/baby' },
    { id: 'Adela, cewe, cewe indo, tobrut', title: 'Adela main di kamar', image: AdelaaImg, link: '/adela' },
    { id: 'AI, cewe, cewe indo, tobrut', title: 'Cewe indo Muka AI', image: AiImg, link: '/aiIndo' },
    { id: 'Andini, cewe, cewe indo, tobrut', title: 'Andini', image: AndiImg, link: '/andini' },
    { id: 'Arrayesa, cewe, cewe indo, tobrut', title: 'Arrayesa si tiktoker tobrut', image: ArrayesaImg, link: '/arrayesa' },
    { id: 'Audrey, cewe, cewe indo, tobrut', title: 'Artis Audery', image: AudreyImg, link: '/audrey' },
    { id: 'Aulia, cewe, cewe indo, tobrut', title: 'Aulia indonesian girls', image: AuliaImg, link: '/aulia' },

];

export default CardData;