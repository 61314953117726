import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainPage from "./Pages/MainPage/MainPage";
import LalaMainPage from "./Pages/Indonesia/Lala/LalaMainPage";
import SintaMahalini from "./Pages/Indonesia/SintaMahalini/SintaMahalini";
import SindiNisrina from "./Pages/Indonesia/SindiNisrina/SindiNisrina";
import Shasha from "./Pages/Indonesia/ShaSha/ShaSha";
import SariPage from "./Pages/Indonesia/Sari/SariPage";
import SaniPage from "./Pages/Indonesia/Sani/SaniPage";
import SalsaPage from "./Pages/Indonesia/Salsa/SalsaPage";
import RiaPage from "./Pages/Indonesia/Ria/RiaPage";
import PutiPage from "./Pages/Indonesia/Puti/PutiPage";
import NurHannahPage from "./Pages/Indonesia/NurHannah/NurHannahPage";
import MaeganPage from "./Pages/Indonesia/Maegan/MaeganPage";
import KaylaPage from "./Pages/Indonesia/Kayla/KaylaPage";
import IrdaPage from "./Pages/Indonesia/Irda/IrdaPage";
import EvaPage from "./Pages/Indonesia/Eva/EvaPage";
import ChikaPage from "./Pages/Indonesia/Chika/ChikaPage";
import BabyPage from "./Pages/Indonesia/Baby/BabyPage";
import Adela from "./Pages/Indonesia/Adela/Adela";
import AiPage from "./Pages/Indonesia/Ai/AiPage";
import Andini from "./Pages/Indonesia/Andini/Andini";
import ArrayesaPage from "./Pages/Indonesia/Arrayesa/ArrayesaPage";
import AuliaPage from "./Pages/Indonesia/Aulia/AuliaPage";
import AudreyPage from "./Pages/Indonesia/Audrey/AudreyPage";
import AngelicaPage from "./Pages/Indonesia/Angelica/AngelicaPage";
import NanaPage from "./Pages/Indonesia/Nana/NanaPage";
import WindaPage from "./Pages/Indonesia/Winda/WindaPage";
import Pasangan1 from "./Pages/Indonesia/Pasangan1/Pasangan1";
import Pasangan2 from "./Pages/Indonesia/Pasangan2/Pasangan2";
import AlicelebelPage from "./Pages/Europa/Alicelebel/AlicelebelPage";
import YumimiaPage from "./Pages/Europa/Yumimia/YumimiaPage";
import OneeChewPage from "./Pages/Europa/OneeChew/OneeChewPage";
import KateBlearePage from "./Pages/Europa/KateBleare/KateBlearePage";
import Jennifer from "./Pages/Europa/Jennifer/Jennifer.jsx";
import EmmaWishPage from "./Pages/Europa/EmmaWish/EmmaWishPage.jsx";
import IvyWhytePahe from "./Pages/Europa/IvyWhyte/IvyWhytePahe.jsx";
import JuliaPage from "./Pages/Europa/Julia/JuliaPage.jsx";

const App = () => {
  return (

    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/lala" element={<LalaMainPage />} />
        <Route path="/sintamahalini" element={<SintaMahalini />} />
        <Route path="/sindinisrina" element={<SindiNisrina />} />
        <Route path="/shasha" element={<Shasha />} />
        <Route path="/sari" element={<SariPage />} />
        <Route path="/sani" element={<SaniPage />} />
        <Route path="/salsa" element={<SalsaPage />} />
        <Route path="/ria" element={<RiaPage />} />
        <Route path="/puti" element={<PutiPage />} />
        <Route path="/hanna" element={<NurHannahPage />} />
        <Route path="/maegan" element={<MaeganPage />} />
        <Route path="/kayla" element={<KaylaPage />} />
        <Route path="/irda" element={<IrdaPage />} />
        <Route path="/eva" element={<EvaPage />} />
        <Route path="/chika" element={<ChikaPage />} />
        <Route path="/baby" element={<BabyPage />} />
        <Route path="/adela" element={<Adela />} />
        <Route path="/aiIndo" element={<AiPage />} />
        <Route path="/andini" element={<Andini />} />
        <Route path="/arrayesa" element={<ArrayesaPage />} />
        <Route path="/audrey" element={<AudreyPage />} />
        <Route path="/aulia" element={<AuliaPage />} />
        <Route path="/angelica" element={<AngelicaPage />} />
        <Route path="/nana" element={<NanaPage />} />
        <Route path="/winda" element={<WindaPage />} />
        <Route path="/pasangan1" element={<Pasangan1 />} />
        <Route path="/pasangan2" element={<Pasangan2 />} />
        <Route path="/alicelebel" element={<AlicelebelPage />} />
        <Route path="/yumimia" element={<YumimiaPage />} />
        <Route path="/onee-chew" element={<OneeChewPage />} />
        <Route path="/katebleare" element={<KateBlearePage />} />
        <Route path="/jennifer" element={<Jennifer/>} />
        <Route path="/emmawish" element={<EmmaWishPage/>} />
        <Route path="/ivywhyte" element={<IvyWhytePahe/>} />
        <Route path="/lovejulia" element={<JuliaPage/>} />

      </Routes>
    </BrowserRouter>



  )
}

export default App;
