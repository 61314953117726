import React, { useEffect, useState } from 'react'
import RiaImg from '../../../Images/ria.jpg'
import NavbarComponent from '../../../components/Navbar/NavbarComponent'
import FooterComponent from '../../../components/Footer/FooterComponent'
import '../../GlobalDetailPage.css'
import { Button, Spinner, Row, Col, } from 'react-bootstrap'

const RiaPage = () => {
    const [loading, isLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            isLoading(false);
        }, 3000);
        return () => clearTimeout(timer)
    }, []);

    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = '//pl23664610.highrevenuenetwork.com/37/4a/83/374a83732a9ad95c18ad5281d5097e1b.js';
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    if (loading) {
        return <div className="spinner-container">
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>;
    }

    return (
        <div className='detail-main-page'>
            <NavbarComponent />

            <div className=''>
                <div className='img-cover-section'>
                    <img className='img-cover' src={RiaImg} alt="" />
                </div>
            </div>
            <div className=''>

                <Row className='d-flex align-item-center justify-content-center'>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1Hj4KxVXqEZZ6pLYY7oxrTL_Uik9-LSRD?usp=drive_link">Episode 1</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1GKIY5JnsO1ipDky3ggPiolTMcpbl-Sbs?usp=drive_link">Episode 2</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1QozAHZYYNkPmzZy4VErdZa2ccB6LbBDK?usp=drive_link">Episode 3</Button>
                    </Col>
                     <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1vdC-3XOwVIePg1G_ZMchZnMCN9YX4JfB?usp=drive_link">Episode 4</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1Ab_K6W0uHt2mOhZpkQ3n9AzKZKPSCtKr?usp=drive_link">Episode 5</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/11vCQaRPS--WbscrFDkIcpEGwQgZILOEN?usp=drive_link">Episode 6</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1MgmhwlXyfC-GR0K1AORxAFjsrCspvDyd?usp=drive_link">Episode 7</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1Rdi9bwlc4JA8vp3VTszJdrGVrIF1xRsO?usp=drive_link">Episode 8</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1nPsS25v2UEKywV61bmcrELc3r9Ug9ty8?usp=drive_link">Episode 9</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1jII1TJY_HbRFqvH9P1gY1w2mjs-rPIPS?usp=drive_link">Episode 10</Button>
                    </Col>
                   

                </Row>

            </div>
            <FooterComponent />
        </div>
    )
}

export default RiaPage
