import React, { useEffect, useState } from 'react'
import HannaImg from '../../../Images/hanna.jpg'
import NavbarComponent from '../../../components/Navbar/NavbarComponent'
import FooterComponent from '../../../components/Footer/FooterComponent'
import '../../GlobalDetailPage.css'
import { Button, Spinner, Row, Col, } from 'react-bootstrap'

const NurHannahPage = () => {

    const [loading, isLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            isLoading(false);
        }, 3000);
        return () => clearTimeout(timer)
    }, []);

    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = '//pl23664610.highrevenuenetwork.com/37/4a/83/374a83732a9ad95c18ad5281d5097e1b.js';
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    if (loading) {
        return <div className="spinner-container">
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>;
    }

    return (
        <div className='detail-main-page'>
            <NavbarComponent />

            <div className=''>
                <div className='img-cover-section'>
                    <img className='img-cover' src={HannaImg} alt="" />
                </div>
            </div>
            <div className=''>

                <Row className='d-flex align-item-center justify-content-center'>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1xSvq4kcXdBfmJFjc7zk0ETwQGa7qkMyd?usp=drive_link">Episode 1</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1G6lbsOxJoQDLmZailq4Oysv9KfY-QRqJ?usp=drive_link">Episode 2</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1bU-GHSqfmEKpe9D5WHHyevZyHWYq4361?usp=drive_link">Episode 3</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1EckD_oa8b2lfs8PQBrqPXkXedg2r0U-e?usp=drive_link">Episode 4</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1EbI8whwbo7Gb4WT0cCNVM42uzwjgnmEy?usp=drive_link">Episode 5</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1aYlSAL6oZUjRCTm4k1D32Ve_OwYjVujh?usp=drive_link">Episode 6</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1As6AkBz0igxJK6znCcs9uU1xkQQKzVWL?usp=drive_link">Episode 7</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1gUH3ozdf0oAYEdbETufXPkupT0TXANPd?usp=drive_link">Episode 8</Button>
                    </Col>
                </Row>

            </div>
            <FooterComponent />
        </div>
    )
}

export default NurHannahPage
