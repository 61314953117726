import React, { useEffect, useState } from 'react'
import ShashaImg from '../../../Images/ShaSha.jpg'
import NavbarComponent from '../../../components/Navbar/NavbarComponent'
import FooterComponent from '../../../components/Footer/FooterComponent'
import '../../GlobalDetailPage.css'
import { Button, Spinner, Row, Col, } from 'react-bootstrap'

const ShaSha = () => {
    const [loading, isLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            isLoading(false);
        }, 3000);
        return () => clearTimeout(timer)
    }, []);

    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = '//pl23664610.highrevenuenetwork.com/37/4a/83/374a83732a9ad95c18ad5281d5097e1b.js';
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    if (loading) {
        return <div className="spinner-container">
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>;
    }

    return (
        <div className='detail-main-page'>
            <NavbarComponent />

            <div className=''>
                <div className='img-cover-section'>
                    <img className='img-cover' src={ShashaImg} alt="" />
                </div>
            </div>
            <div className=''>

                <Row className='d-flex align-item-center justify-content-center'>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1-DSbutsCfuSyKwhZ8bJVNIb1YwU-l8SN?usp=drive_link">Episode 1</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1X1-NnUrReXkh1GiawjEoaemmHrtcYQK3?usp=drive_link">Episode 2</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1s8Yv7bsWA4tdkIlUtVaV8OdLcppccGSU?usp=drive_link">Episode 3</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1AmXMWkhlXfzDKN_YLD6EzUcF7GsNS5Ge?usp=drive_link">Episode 4</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/19yLD07Vb26oO7yJS-MFPZ27kfK4KGjWj?usp=drive_link">Episode 5</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1cgT46X9h38ycd2c3zhy48vmVsV5c1ECb?usp=drive_link">Episode 6</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1O91_v-M40K99ifNtKWa8U0QtsbP6IbYl?usp=drive_link">Episode 7</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1Z-nX-cAOkkPbPYmZOJWe-IOdze2nWhZ9?usp=drive_link">Episode 8</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1lB1Bpt0OXNOP1DuRBeq3CcHGC7HY3gM5?usp=drive_link">Episode 9</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1or9yHt9Pv3iwpyDAEiUb4MVw8hJunvyG?usp=drive_link">Episode 10</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1IpV2Y7ethf7C--8lWg3YnHplcUgU60UZ?usp=drive_link">Episode 11</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1N0w0qVW1AzNureQZ6KEjcjCn3dQjjBQm?usp=drive_link">Episode 12</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1bY4AarOvn-OkHmBsq3vfosAXVJkdQ4jg?usp=drive_link">Episode 13</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1YT47_8fU-z9ldB0q4Dt8afP7fQemiVi7?usp=drive_link">Episode 14</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1MANSfDtO2ZaVB2qTbjHsxTxQHduGbL3I?usp=drive_link">Episode 15</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1d7NWH9ervi6XRKEFttsKXXhEJX74_8Vz?usp=drive_link">Episode 16</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1Q3zpKG1w5yNZOH1RaVbWRLeSKkzFkTRf?usp=drive_link">Episode 17</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1wxtsvGAdrMO-vWUH__vbPQnqjc8X5Dp4?usp=drive_link">Episode 18</Button>
                    </Col>
                </Row>

            </div>
            <FooterComponent />
        </div>
    )
}

export default ShaSha
