import React, { useEffect, useState } from 'react'
import Pasangan2Img from '../../../Images/pasangan2.jpg'
import NavbarComponent from '../../../components/Navbar/NavbarComponent'
import FooterComponent from '../../../components/Footer/FooterComponent'
import '../../GlobalDetailPage.css'
import { Button, Spinner, Row, Col, } from 'react-bootstrap'

const Pasangan2 = () => {
    const [loading, isLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            isLoading(false);
        }, 3000);
        return () => clearTimeout(timer)
    }, []);

    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = '//pl23664610.highrevenuenetwork.com/37/4a/83/374a83732a9ad95c18ad5281d5097e1b.js';
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    if (loading) {
        return <div className="spinner-container">
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>;
    }

    return (
        <div className='detail-main-page'>
            <NavbarComponent />

            <div className=''>
                <div className='img-cover-section'>
                    <img className='img-cover' src={Pasangan2Img} alt="" />
                </div>
            </div>
            <div className=''>
                <Row className='d-flex align-item-center justify-content-center'>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1Td8boHs_A_Xvp_TOEXZ-rydiBmrTlL13?usp=drive_link">Episode 1</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1ebrCKQy3DpIF1wEs3KN4BHaguTBJ3QsO?usp=drive_link">Episode 2</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1sZ1dugujPuQWpUhzegIR6Zk94HmVeU72?usp=drive_link">Episode 3</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1xfzT8lQwDLf8YHsX_fWQ_X0Qg-wCFKfy?usp=drive_link">Episode 4</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1_0fCoUlBzTSKpfC0AF16uI6Re3CcJbFQ?usp=drive_link">Episode 5</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1iwIxnwZPsUJLv6wcRGLMZtERYet5SO8Z?usp=drive_link">Episode 6</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1e01UGVjBqQaoFHPvwwzRWu1rmfJLZgBe?usp=drive_link">Episode 7</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1WG69i685PNANjVDQ2Nyw0UjcaBep3tMB?usp=drive_link">Episode 8</Button>
                    </Col>
                </Row>
            </div>
          
            <FooterComponent />
        </div>
    )
}

export default Pasangan2