import React, { useEffect, useState } from "react";
import './MainPage.css';
import { Card, Row, Col, Container, Pagination, Spinner } from 'react-bootstrap';
import CardData from '../../Services/CardData'
import { useNavigate } from "react-router-dom";
import NavbarComponent from "../../components/Navbar/NavbarComponent";
import FooterComponent from "../../components/Footer/FooterComponent";



const MainPage = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCards, setFilteredCards] = useState(CardData);
    const cardsPerPage = 8;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true)
    const [loadingPage, setLoadingPage] = useState(false);

    const indexOfLastCard = currentPage * cardsPerPage;
    const indexOfFirstCard = indexOfLastCard - cardsPerPage;
    const currentCards = filteredCards.slice(indexOfFirstCard, indexOfFirstCard + cardsPerPage);

    const paginate = (pageNumber) => {
        setLoadingPage(true);
        setTimeout(() => {
            setCurrentPage(pageNumber);
            setLoadingPage(false);
        }, 2000)
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 3000)
        return () => clearTimeout(timer);
    })

    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = '//www.topcreativeformat.com/1f1f428b0416efd4e85451cb21c344f7/invoke.js';
        script.async = true;

        const atOptions = {
            key: 'b8502f4836d21e5ef92c8bc333c010a3',
            format: 'iframe',
            height: 50,
            width: 320,
            params: {}
        };

        const scriptTag = document.createElement('script');
        scriptTag.type = 'text/javascript';
        scriptTag.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;

        const adContainer = document.getElementById('ad-container');
        adContainer.appendChild(scriptTag);
        adContainer.appendChild(script);

        return () => {
            adContainer.removeChild(scriptTag);
            adContainer.removeChild(script);
        };
    }, []);



    const handleSearch = (term) => {
        setSearchTerm(term);
        setLoading(true);
        if (term) {
            setTimeout(() => {
                setFilteredCards(CardData.filter(card => card.id.toLowerCase().includes(term.toLowerCase())));
                setLoading(false);
            }, 3000)
        } else {
            setTimeout(() => {
                setFilteredCards(CardData);
                setLoading(false)
            }, 2000)
        }
    };


    

    const RenderCards = () => {
        if (currentCards.length === 0) {

            return <div className="text-center mt-5">Not Founds</div>
        }
        if (loading) {
            return <div className="spinner-container">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>;
        }
        if (loadingPage) {
            return <div className="spinner-container">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>;
        }
        return currentCards.map(card => (
            <Col key={card.id} xs={12} sm={6} md={4} lg={3}>
                <Card className="mb-4">
                    <Card.Img className="img-fluid img-300" variant="top" src={card.image} onClick={() => navigate(card.link)} />
                    <Card.Body>
                        <Card.Title>{card.title.length > 50 ? `${card.title.substring(0, 25)}...` : card.title}</Card.Title>
                        <Card.Text>{card.text}</Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        ));
    };

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredCards.length / cardsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="main-page">
            
            <NavbarComponent onSearch={handleSearch} />
            
            <div id="ad-container" className="ad-container">
                {/* Iklan akan dimuat di sini */}
            </div>
            <Container>
                <Row>
                    {RenderCards()}
                </Row>
                <Pagination className="justify-content-center mt-5" size="sm" >
                    {pageNumbers.map(number => (
                        <Pagination.Item className="btn-outline-dark" key={number} active={number === currentPage} onClick={() => paginate(number)}>
                            {number}
                        </Pagination.Item>
                    ))}
                </Pagination>
            </Container>

            
           
            <FooterComponent />
        </div>
    );
};

export default MainPage;