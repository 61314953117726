import React, { useEffect, useState } from 'react'
import SaniImg from '../../../Images/Sani.jpg'
import NavbarComponent from '../../../components/Navbar/NavbarComponent'
import FooterComponent from '../../../components/Footer/FooterComponent'
import '../../GlobalDetailPage.css'
import { Button, Spinner, Row, Col, } from 'react-bootstrap'

const SaniPage = () => {
    const [loading, isLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            isLoading(false);
        }, 3000);
        return () => clearTimeout(timer)
    }, []);

    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = '//pl23664610.highrevenuenetwork.com/37/4a/83/374a83732a9ad95c18ad5281d5097e1b.js';
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    if (loading) {
        return <div className="spinner-container">
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>;
    }

    return (
        <div className='detail-main-page'>
            <NavbarComponent />

            <div className=''>
                <div className='img-cover-section'>
                    <img className='img-cover' src={SaniImg} alt="" />
                </div>
            </div>
            <div className=''>

                <Row className='d-flex align-item-center justify-content-center'>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1_LhuiDOvxF9V00534QbckUVLk_llCYnQ?usp=drive_link">Episode 1</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1DBJbF_XVv3QkbWVHhQ-8r4RD-JlMd0v-?usp=drive_link">Episode 2</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1H_sC06z--ENxsuZiiFBiXDSBiEuEXWvj?usp=drive_link">Episode 3</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/141ZTxtw2EQbGBH2PlkFBXPtWnrNC__mK?usp=drive_link">Episode 4</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1ydLFFUp1S4sbeZh2PAJ2dp3GKSxwePRe?usp=drive_link">Episode 5</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1m-_XeS7QUYYKuOKJjAnNuE8DM53S09O7?usp=drive_link">Episode 6</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/12ZpsojnRTrhfGo_yyeW_BLM14XNszqaD?usp=drive_link">Episode 7</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1fOk-z-ztM6sKLABiFlYpWfvLKMcXJnqa?usp=drive_link">Episode 8</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1zWxZgzIEBsjGiQGvUfbpaWEKRfXBdm65?usp=drive_link">Episode 9</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1Gj2PjUIopcS-_He72Hl1P72_MfRkLBRS?usp=drive_link">Episode 10</Button>
                    </Col>
                    <Col xl={2} sm={4} className='d-flex justify-content-center align-item-center mb-3'>
                        <Button className='btn-episode' variant='outline-dark' href="https://drive.google.com/drive/folders/1pjMNEziWlskpjKSicPTDjijfyMt-UxWi?usp=drive_link">Episode 11</Button>
                    </Col>
            
                </Row>

            </div>
            <FooterComponent />
        </div>
    )
}

export default SaniPage
